var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-col',{attrs:{"cols":"12","xl":"11"}},[_c('b-card',{staticClass:"card border-0 hp-invoice-card",attrs:{"id":"invoice"}},[_c('b-row',{attrs:{"align-h":"between"}},[_c('b-col',{attrs:{"cols":"12","lg":"12"}},[_c('div',{staticClass:"col-lg-12 col-12",staticStyle:{"display":"flex"}},[_c('b-form-group',{attrs:{"id":"input-group-2","label":"","label-for":"profile_picture"}},[_c('div',{staticStyle:{"margin-left":"3px","margin-bottom":"15px"}},[_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":'https://api.driveloop.cionax.com/' + _vm.logo,"alt":"Profile Picture","width":"100px;","height":"100px;"}})])]),_c('div',{staticStyle:{"float":"right","margin-top":"10px","margin-left":"10px"},attrs:{"cols":"6"}},[_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Company name: ")]),_vm._v(" "+_vm._s(_vm.company_name)+" ")]),_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Phone:")]),_vm._v(" "+_vm._s(_vm.company_phone)+" ")]),_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.company_email)+" ")])]),_c('div',{staticStyle:{"float":"right","margin-top":"10px","margin-left":"10px"},attrs:{"cols":"6"}},[_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Number: ")]),_vm._v(" "+_vm._s(_vm.company_number)+" ")]),_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Vat:")]),_vm._v(" "+_vm._s(_vm.vat_number)+" ")]),_c('p',{style:({
                fontSize: _vm.textSize,
                marginLeft: _vm.marginLeft,
                marginTop: _vm.marginTop,
              })},[_c('b',[_vm._v("Address:")]),_vm._v(" "+_vm._s(_vm.company_address)+" ")])])],1)])],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"20px"},attrs:{"cols":"4"}},[_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Owner: ")]),_vm._v(" "+_vm._s(_vm.owner_name)+" ")]),_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Phone:")]),_vm._v(" "+_vm._s(_vm.owner_number)+" ")]),_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.owner_email)+" ")]),_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Address:")]),_vm._v(" "+_vm._s(_vm.owner_address)+" ")])]),_c('b-col',{staticStyle:{"margin-top":"20px"},attrs:{"cols":"4"}},[_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Documents:")])]),_vm._l((_vm.editedUser.company_document),function(company_document,index){return _c('div',{key:index,staticStyle:{"margin-left":"3px","margin-bottom":"15px"}},[_c('img',{staticStyle:{"border-radius":"0%"},attrs:{"src":'https://api.driveloop.cionax.com/' + company_document,"alt":"Profile Picture","width":"100px;","height":"100px;"}})])})],2),_c('b-col',{staticStyle:{"margin-top":"20px"},attrs:{"cols":"4"}},[_c('p',{style:({
            fontSize: _vm.textSize,
            marginLeft: _vm.marginLeft,
            marginTop: _vm.marginTop,
          })},[_c('b',[_vm._v("Contract:")])]),_c('div',{staticStyle:{"margin-left":"3px","margin-bottom":"15px"}},[_c('img',{staticStyle:{"border-radius":"0%"},attrs:{"src":'https://api.driveloop.cionax.com/' + _vm.contract,"alt":"Profile Picture","width":"80px;","height":"80px;"}})])])],1),_c('div',{staticClass:"divider"}),_c('b-row',[_c('b-col',{staticStyle:{"margin-top":"20px"},attrs:{"cols":"12"}},[_c('b-table-simple',{attrs:{"small":"","caption-top":"","responsive":"","borderless":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.driverInvoices}},[_c('span',[_c('b',[_vm._v(" Invoice")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.driverEarningReportlists}},[_c('span',[_c('b',[_vm._v(" Earning Report")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.expense}},[_c('span',[_c('b',[_vm._v(" Expense")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.driverReports}},[_c('span',[_c('b',[_vm._v(" Report")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.vehicle}},[_c('span',[_c('b',[_vm._v("See Vehicle")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.driver}},[_c('span',[_c('b',[_vm._v(" Driver")])])]),_c('b-button',{staticClass:"mb-8 mr-8",staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"},on:{"click":_vm.editCompany}},[_c('span',[_c('b',[_vm._v(" Edit")])])])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }