<template>
  <b-col cols="12" xl="11">
    <b-card id="invoice" class="card border-0 hp-invoice-card">
      <b-row align-h="between">
        <b-col cols="12" lg="12">
          <div class="col-lg-12 col-12" style="display: flex">
            <b-form-group
              id="input-group-2"
              label=""
              label-for="profile_picture"
            >
              <div style="margin-left: 3px; margin-bottom: 15px">
                <img
                  :src="'https://api.driveloop.cionax.com/' + logo"
                  alt="Profile Picture"
                  width="100px;"
                  height="100px;"
                  style="border-radius: 50%"
                />
              </div>
            </b-form-group>
            <div
              cols="6"
              style="float: right; margin-top: 10px; margin-left: 10px"
            >
              <!-- <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }"> <b>Name: </b>{{ name }} {{ last_name }}
                </p> -->
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Company name: </b>
                {{ company_name }}
              </p>
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Phone:</b>
                {{ company_phone }}
              </p>
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Email:</b>
                {{ company_email }}
              </p>
            </div>
            <div
              cols="6"
              style="float: right; margin-top: 10px; margin-left: 10px"
            >
              <!-- <p :style="{ fontSize: textSize, marginLeft: marginLeft, marginTop: marginTop }"> <b>Name: </b>{{ name }} {{ last_name }}
                </p> -->
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Number: </b>
                {{ company_number }}
              </p>
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Vat:</b>
                {{ vat_number }}
              </p>
              <p
                :style="{
                  fontSize: textSize,
                  marginLeft: marginLeft,
                  marginTop: marginTop,
                }"
              >
                <b>Address:</b>
                {{ company_address }}
              </p>
            </div>
          </div>
        </b-col>
        <!-- <b-col cols="12" md="4" class="text-left text-sm-right hp-print-info">
  
            <p>Joining Date: {{ joining_date }}</p>
          </b-col> -->
      </b-row>
      <div class="divider"></div>
      <b-row>
        <b-col cols="4" style="margin-top: 20px">
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Owner: </b>
            {{ owner_name }}
          </p>
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Phone:</b>
            {{ owner_number }}
          </p>
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Email:</b>
            {{ owner_email }}
          </p>
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Address:</b>
            {{ owner_address }}
          </p>
        </b-col>
        <b-col cols="4" style="margin-top: 20px">
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Documents:</b>
          </p>
          <div v-for="(company_document, index) in editedUser.company_document" :key="index" style="margin-left: 3px; margin-bottom: 15px">
            <img
              :src="'https://api.driveloop.cionax.com/' + company_document"
              alt="Profile Picture"
              width="100px;"
              height="100px;"
              style="border-radius: 0%"
            />
          </div>
        </b-col>
        <b-col cols="4" style="margin-top: 20px">
          <p
            :style="{
              fontSize: textSize,
              marginLeft: marginLeft,
              marginTop: marginTop,
            }"
          >
            <b>Contract:</b>
          </p>
          <div style="margin-left: 3px; margin-bottom: 15px">
            <img
              :src="'https://api.driveloop.cionax.com/' + contract"
              alt="Profile Picture"
              width="80px;"
              height="80px;"
              style="border-radius: 0%"
            />
          </div>
        </b-col>
      </b-row>

      <div class="divider"></div>
      <b-row>
        <b-col cols="12" style="margin-top: 20px">
          <b-table-simple small caption-top responsive borderless>
            <b-thead>
              <b-tr class="text-center">
               
                   <b-button
                  @click="driverInvoices"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Invoice</b></span>
                </b-button>
                <b-button
                  @click="driverEarningReportlists"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Earning Report</b></span>
                </b-button>
                  <b-button
                  @click="expense"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Expense</b></span>
                </b-button>


                <b-button
                  @click="driverReports"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Report</b></span>
                </b-button>
                <b-button
                  @click="vehicle"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b>See Vehicle</b></span>
                </b-button>
               
              
                <b-button
                  @click="driver"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Driver</b></span>
                </b-button>
                <b-button
                  @click="editCompany"
                  variant="primary"
                  class="mb-8 mr-8"
                  style="margin-top: 10px"
                >
                  <span><b> Edit</b></span>
                </b-button>
               
              </b-tr>
            </b-thead>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
  
  <script>
import {
  BRow,
  BLink,
  BButton,
  BCol,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BCard,
  BFormGroup,
} from "bootstrap-vue";
import axios from "axios";

export default {
  data() {
    return {
      selectedType: "",
      show: true,
      //   codeText: code.introduction,
      //   codeActive: false,
      //   codeActiveClass: false,
      isLoading: false,
      // new code
      // Car Maintenance
      owner_name: "",
      owner_number: "",
      company_document: null,
      company_name: "",
      category: "",
      vat_number: "",
      company_phone: "",
      company_email: "",
      company_address: "",
      owner_email: "",
      owner_phone: "",
      owner_address: "",
      company_number: "",
      admin_fee: "",
      report: "",
      bank_name: "",
      bank_account_number: "",
      logo: "",
      editedUser: {
        company_document: [], // Initialize the array here
      },

    };
  },

  components: {
    BRow,
    BLink,
    BButton,
    BCol,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCard,
    BFormGroup,
  },

  created() {
    const userId = this.$route.params.id;
    axios
      .get(`company/${userId}`)
      .then((response) => {
        this.editedUser = response.data.data;
        this.id = this.editedUser.id;
        this.owner_name = this.editedUser.owner_name;
        this.owner_number = this.editedUser.owner_number;
        this.company_document = this.editedUser.company_document;
        this.company_name = this.editedUser.company_name;
        this.category = this.editedUser.category;
        this.vat_number = this.editedUser.vat_number;
        this.company_phone = this.editedUser.company_phone;
        this.company_email = this.editedUser.company_email;
        this.company_address = this.editedUser.company_address;
        this.owner_email = this.editedUser.owner_email;
        this.owner_phone = this.editedUser.owner_phone;
        this.owner_address = this.editedUser.owner_address;
        this.company_number = this.editedUser.company_number;
        this.admin_fee = this.editedUser.admin_fee;
        this.report = this.editedUser.report;
        this.contract = this.editedUser.contract;
        this.bank_name = this.editedUser.bank_name;
        this.bank_account_number = this.editedUser.bank_account_number; 
        this.logo = this.editedUser.logo;

        // ... and so on for other properties ...
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
      // debugger
    },
    onReset(event) {
      event.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //    Add Vehicle
    addUser() {
      this.isLoading = true;
      // Create a FormData object to handle the image file
      const formData = new FormData();
      formData.append("owner_name", this.owner_name);
      formData.append("owner_number", this.owner_number);
        // formData.append("company_document", this.company_document);
        for (const document of this.company_document) {
        formData.append("company_document[]", document);
      }
      formData.append("company_name", this.company_name);
      formData.append("category", this.category);
      formData.append("vat_number", this.vat_number);
      formData.append("company_phone", this.company_phone);
      formData.append("company_email", this.company_email);
      formData.append("company_address", this.company_address);
      formData.append("owner_email", this.owner_email);
      formData.append("owner_phone", this.owner_phone);
      formData.append("owner_address", this.owner_address);
      formData.append("contract", this.contract);
      formData.append("company_number", this.company_number);
      formData.append("admin_fee", this.admin_fee);
      formData.append("report", this.report);
      formData.append("bank_name", this.bank_name);
      formData.append("bank_account_number", this.bank_account_number);
      formData.append("logo", this.logo);

      axios
        .post(`companyUpdate/${this.editedUser.id}`, formData)
        .then((response) => {
          console.log(response.data);
          this.$bvToast.toast("company added successfully!", {
            title: "Success",
            variant: "success",
            solid: true,
            appendToast: true,
            toaster: "b-toaster-top-right",
            autoHideDelay: 5000,
            variant: "primary", // Background color
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.errors = error.response.data.errors;
          console.log(error.response.data);
          this.isLoading = false;
        });
    },

    // codeClick() {
    //   this.codeActive = !this.codeActive;

    //   setTimeout(() => {
    //     this.codeActiveClass = !this.codeActiveClass;
    //   }, 100);
    // },

    onCompanyDocumentChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.company_document = file;
      }
    },
    onCompanyContractChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.contract = file;
      }
    },
    //     vehicle() {
    //       this.$router.push({ name: 'vehicleID', params: { id: this.vehicle_id } });
    // },
                
    editCompany() {
      this.$router.push({
        name: "editCompany",
        params: { id: this.id },
      });
    },
    vehicle() {
        this.$router.push({
            name: "/B2B/Vehicle/lists",
            params: { id: this.id },
        });
    },
    expense() {
      this.$router.push({
        name: "companyExpnse",
        params: { id: this.id },
      });
    },

    driverReports() {
      this.$router.push({
        name: "driverReport",
        params: { id: this.idFromUrl },
      });
    },

    driverInvoices() {
      this.$router.push({
        name: "driverInvoice",
        params: { id: this.idFromUrl },
      });
    },

    driverEfficiencyReports() {
      this.$router.push({
        name: "driverEfficiencyReport",
        params: { id: this.idFromUrl },
      });
    },

    driverMaintenances() {
      this.$router.push({
        name: "driverMaintenance",
        params: { id: this.vehicle_id },
      });
    },
    driver() {
        this.$router.push({
          name: "driver",
          params: { id: this.id },
        });
    },

    editprofile() {
      this.$router.push({
        name: "editDrivers",
        params: { id: this.idFromUrl },
      });
    },

    driverEarningReportlists() {
      this.$router.push({
        name: "companyEarningReportlist",
        params: {
          id: this.id,
        },
      });
    },

    codeClick() {
      this.codeActive = !this.codeActive;
    },

    saveOwnCar() {
      this.showModal = false;
    },
  },
};
</script>